export const getBase64FromFile = (data: File | Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.readAsDataURL(data);
        reader.onload = () => {
            reader.result && resolve(reader.result.toString());
        };
        reader.onerror = reject;
    });
};
